import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React from 'react';
import { closeNotification } from 'store/appSlice/appSlice';
import { green } from 'theme/theme';

const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {
  return <Alert variant="filled" {...props} ref={ref} />;
});

export const Notification = () => {
  const { notification } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(closeNotification());
  };
  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={notification.severity}
        sx={{ backgroundColor: notification.severity === 'success' ? green.main : null }}
      >
        {notification.message}
      </MuiAlert>
    </Snackbar>
  );
};
