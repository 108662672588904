import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrdersThunk } from './thunk/getOrdersThunk';

export interface ShopState {
  orders: any; //todo: update to actual type
}

const initialState: ShopState = {
  orders: null,
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShopAction: (state, action: PayloadAction<any>) => {
      state.orders = action.payload;
    },
  },
  extraReducers: {
    [getOrdersThunk.fulfilled.type]: (state, action) => {
      state.orders = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShopAction } = shopSlice.actions;

export default shopSlice.reducer;
