import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHasGarminConnected } from 'hooks/useHasGarminConnected';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { green } from 'theme/theme';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '16px 32px',
  },
});

export const ConnectWatchView = () => {
  const classes = useStyles();
  const hasGarminConnected = useHasGarminConnected();

  const location = useLocation();
  const reportGAConnectWatchButtonClicked = () => {
    // @ts-ignore
    window.dataLayer.push({});

    window.dataLayer.push({
      event: 'connectAwatch',
      watchType: 'Garmin',
      pageTitle: location.pathname,
    });
  };

  return (
    <div className={classes.wrapper}>
      {hasGarminConnected ? (
        <Typography variant="h5" sx={{ lineHeight: '24px', color: green.main }}>
          You have already connected your watch!
        </Typography>
      ) : (
        <>
          <Typography variant="h5">If you measure stress you can manage it</Typography>
          <Typography variant="body1" sx={{ margin: '16px 0', textAlign: 'center' }}>
            We take privacy very seriously and all of the information you share with us is strictly confidential. We
            only use it to personalize the right bars for you. In order to better learn how your body and mind balance
            and continuously adjust to your changing stress status, connect to your smart watch.
          </Typography>
          <Button
            href={`${process.env.REACT_APP_BASE_URL_API}/devices/garmin/authenticate`}
            onClick={reportGAConnectWatchButtonClicked}
            variant="contained"
          >
            Connect Watch
          </Button>
        </>
      )}
    </div>
  );
};
