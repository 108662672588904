import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserThunk } from './thunk/getUserThunk';
import { updateUserThunk } from './thunk/updateUserThunk';
import { getCombinedResultsThunk } from './thunk/getCombinedResultsThunk';
import { Account, MetricSessionAnswers, User } from 'models/user/User';
import { getAccountThunk } from './thunk/getAccountThunk';
import { getUserQuestionThunk } from './thunk/getUserQuestionThunk';

export interface UserState {
  user: User | null;
  hasAnsweredQuestionnaire: boolean;
  answeredAllQuestions: string;
  sessionAnswers: MetricSessionAnswers[];
  account: Account;
  loading: boolean;
  userQuestion: any | null;
}

const initialState: UserState = {
  user: null,
  hasAnsweredQuestionnaire: false,
  sessionAnswers: [],
  answeredAllQuestions: '',
  account: {
    bars: [],
    order: [],
    upcomingOrder: null,
  },
  loading: false,
  userQuestion: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAction: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    clearAnsweredAllQuestions: state => {
      state.answeredAllQuestions = '';
    },
    remainderResetAction: state => {
      state.userQuestion = null;
    },
    setAnswersAction: (
      state,
      action: PayloadAction<{
        sessionAnswers: MetricSessionAnswers[];
        name: string;
        questionId: string;
        answer: number;
        totalMetricQuestions: number;
      }>,
    ) => {
      const answersCopy = JSON.parse(JSON.stringify(action.payload.sessionAnswers)) as MetricSessionAnswers[];
      const existingMetric = answersCopy.find(metric => metric.metricName === action.payload.name);

      if (existingMetric) {
        const existingAnswer = existingMetric.answers.find(answer => answer.questionId === action.payload.questionId);
        if (existingAnswer) {
          existingAnswer.answer = action.payload.answer;
        } else
          existingMetric.answers.push({
            questionId: action.payload.questionId,
            answer: action.payload.answer,
          });
      } else {
        answersCopy.push({
          metricName: action.payload.name,
          answers: [
            {
              questionId: action.payload.questionId,
              answer: action.payload.answer,
            },
          ],
        });
      }

      state.sessionAnswers = answersCopy;

      const numberOfAnsweredMetricQuestions = answersCopy.find(
        (metric: any) => metric.metricName === action.payload.name,
      )?.answers.length;
      if (numberOfAnsweredMetricQuestions === action.payload.totalMetricQuestions) {
        state.answeredAllQuestions = action.payload.name;
      }
    },
  },
  extraReducers: {
    [getUserThunk.fulfilled.type]: (state, action) => {
      state.user = action.payload;
    },
    [updateUserThunk.fulfilled.type]: (state, action) => {
      state.user = action.payload;
    },
    [getCombinedResultsThunk.fulfilled.type]: (state, action) => {
      state.hasAnsweredQuestionnaire = !!action.payload;
    },
    [getAccountThunk.pending.type]: state => {
      state.loading = true;
    },
    [getAccountThunk.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.account = action.payload;
    },
    [getAccountThunk.rejected.type]: state => {
      state.loading = false;
    },
    [getUserQuestionThunk.fulfilled.type]: (state, action) => {
      state.userQuestion = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserAction, setAnswersAction, clearAnsweredAllQuestions, remainderResetAction } = userSlice.actions;

export default userSlice.reducer;
