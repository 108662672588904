import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { grey } from 'theme/theme';

export const StyledDialog = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    open,
    onCancel,
    title,
    contentTitle,
    body,
    actions,
    bodyText,
    overwriteDialogBody,
    paperSx = { padding: '24px' },
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      PaperProps={{ square: true, sx: paperSx }}
    >
      {title && (
        <DialogTitle id="form-dialog-title" sx={{ textAlign: 'center' }}>
          {title}
        </DialogTitle>
      )}
      {overwriteDialogBody ? (
        overwriteDialogBody
      ) : (
        <>
          <DialogContent
            sx={{
              fontFamily: 'Poppins, Light',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              margin: '16px',
              color: grey.main,
            }}
          >
            {contentTitle && <Typography variant="h3">{contentTitle}</Typography>}
            {bodyText && <DialogContentText>{bodyText}</DialogContentText>}
            {body}
          </DialogContent>
          <DialogActions
            sx={{
              margin: '16px',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            {actions}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
