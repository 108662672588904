export const welcomeQuery = `
{
  welcomePageCollection {
    items {
      welcomeText
      descriptionTextBefore
      descriptionTextAfter
      delayBeforeExit
      animationWidth
      animation {
        title
        url
      }
    }
  }
}
`;
