import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppAPI } from '../../../services/appService/app.service';

export const getConfigThunk = createAsyncThunk('app/getConfigThunk', async _thunkAPI => {
  try {
    return await AppAPI.getConfig();
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something wrong...';
  }
});
