import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const userSelect = createSelector(
  (state: RootState) => state.user,
  story => story,
);

export const userNameSelect = createSelector(
  (state: RootState) => state.user,
  user => user?.user?.first_name,
);

export const account = createSelector(
  (state: RootState) => state.user,
  user => user.account,
);

export const userQuestionSelector = createSelector(
  (state: RootState) => state.user.userQuestion,
  question => question,
);

export const userBarsSelector = createSelector(
  (state: RootState) => state.user.account.bars,
  bars => bars,
);
