import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardService } from 'services/dashboardService/dashboard.service';
import { SingleChartDTO } from 'services/dashboardService/models/SingleChartDTO';
import { SectionTypes } from 'models/metric/Metric';

export const getChartThunk = createAsyncThunk(
  'chart/getData',
  async (
    {
      type,
      slug = '',
      subMetric = '',
      chart,
      period,
      compareTo = '',
      chartIndex,
      section,
    }: SingleChartDTO & { chartIndex: number; section: SectionTypes, slug?: string },
    _thunkAPI,
  ) => {
    try {
      const data = await dashboardService.getSingleChart({ type, subMetric, chart, period, compareTo });

      return {
        metricTag: `${type}/${slug}`,
        period,
        chart,
        data,
        chartIndex,
        section,
      };
    } catch (e: any) {
      throw e.response.data.message ? e.response.data.message : 'Something wrong...';
    }
  },
);
