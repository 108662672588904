import React from 'react';
import { makeStyles } from '@mui/styles';
import theme, { grey } from 'theme/theme';

const useStyles = makeStyles({
  burger: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: theme.spacing(0, 1.5),
    zIndex: 1201,

    '&:focus': {
      outline: 'none',
    },

    '& div': {
      width: '1.5rem',
      height: '0.15rem',
      background: grey.main,
      borderRadius: '10px',
      transition: 'all 0.3s linear',
      position: 'relative',
      transformOrigin: '1px',

      '&:first-child': {
        transform: open => (open ? 'rotate(45deg)' : 'rotate(0)'),
      },

      '&:nth-child(2)': {
        opacity: open => (open ? '0' : '1'),
        transform: open => (open ? 'translateX(20px)' : 'translateX(0)'),
      },

      '&:nth-child(3)': {
        transform: open => (open ? 'rotate(-45deg)' : 'rotate(0)'),
      },
    },
  },
});

interface Props {
  open: boolean;
  setOpen: () => void;
}

export const Burger: React.FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles(open);
  return (
    <button className={classes.burger} onClick={setOpen}>
      <div />
      <div />
      <div />
    </button>
  );
};
