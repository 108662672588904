import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import garminImage from 'assets/garmin_logo/garmin_logo.png';
import { SmartWatchesProps } from './SmartWatches.types';
import { useHasGarminConnected } from 'hooks/useHasGarminConnected';
import { ConnectWatchView } from 'containers/UserAccount/components/Account/components/ConnectWatchView/ConnectWatchView';

export const SmartWatches: React.FC<SmartWatchesProps> = ({ showConnectWatch, setShowConnectWatch }) => {
  const hasGarminConnected = useHasGarminConnected();

  return showConnectWatch ? (
    <ConnectWatchView />
  ) : (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '210px',
        marginTop: '20px',
        cursor: hasGarminConnected ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (!hasGarminConnected) {
          setShowConnectWatch(true);
        }
      }}
    >
      <img src={garminImage} alt="Garmin logo" style={{ marginBottom: '8px' }} />
      {hasGarminConnected ? (
        <Typography variant="body1">Garmin watch connected</Typography>
      ) : (
        <Button variant="text">
          <Typography variant="body1">Connect your device to keep track of your stress</Typography>
        </Button>
      )}
    </Box>
  );
};
