import React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useStyles } from './MenuItems.styles';
import { MenuItemsProps } from './MenuItems.types';
// import { useMenuItems } from './hooks/useMenuItems';

export const MenuItems: React.FC<MenuItemsProps> = ({ links, closeDrawer }) => {
  // const hasGarminConnected = useMenuItems();
  const classes = useStyles();
  return (
    <List className={classes.navList} sx={{ padding: 0 }}>
      {links.map((link: any, index: number) =>
        link.externalUrl ? (
          <a href={link.link} key={index}>
            <ListItemButton key={index} className={classes.link} disableRipple={true}>
              <img src={link.icon} alt={`${link.title} icon`} />
              <ListItemText primary={link.title} />
            </ListItemButton>
          </a>
        ) : (
          <Link
            to={link.disabled ? '#' : link.link}
            key={index}
            onClick={() => {
              if (link.action) {
                link.action();
              }
              closeDrawer();
            }}
          >
            <ListItemButton disabled={link.disabled} key={index} className={classes.link} disableRipple={true}>
              <img src={link.icon} alt={`${link.title} icon`} />

              {/* todo: add the "X new insights" line once we have the data from the db */}
              {/* {link.title === 'Insights' ? (
                <div className={classes.insightsLinkWrapper}>
                  <ListItemText primary={link.title} />
                  {hasGarminConnected && (
                    <Typography variant="body1">
                      <FiberManualRecordIcon className={classes.icon} />
                      <span className={classes.insightsText} style={{  fontSize: isMobile ? '14px' : '16px'}}>{`You have ${newInsightsNum} new insights`}</span>
                    </Typography>
                  )}
                </div>
              ) : ( */}
              <ListItemText primary={link.title} />
              {/* )} */}
            </ListItemButton>
          </Link>
        ),
      )}
    </List>
  );
};
