import { makeStyles } from '@mui/styles';
import { blue } from 'theme/theme';

export const useStyles = makeStyles({
  paper: {
    width: 350,
  },
  detailsWrapper: {
    paddingLeft: '24px',
    borderBottom: `1px solid ${blue.light}`,
  },
});
