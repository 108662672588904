export const APP_QUERY = `
query {
  config(id: "a32EIJhC7y1oOCN3wnhJw") {
    insightBackgroundsCollection {
      items {
        url
        title
      }
    }
  }
}
`;
