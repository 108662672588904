const PANEL_SIZE = 50;

export const root = (height: number) =>
  ({
    backgroundColor: '#F7F7F7',
    height: height,
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  } as const);

export const grid = (isMobile: boolean) =>
  ({
    height: '100%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: isMobile ? 'none' : 'auto 1fr',
    gridTemplateRows: isMobile ? '1fr auto' : 'none',
  } as const);

export const navPanel = (isMobile: boolean, isPanelOn: boolean) =>
  ({
    width: isMobile ? '100%' : isPanelOn ? PANEL_SIZE : 0,
    height: isMobile ? (isPanelOn ? PANEL_SIZE : 0) : '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 3,
    zIndex: 1,
    overflow: 'hidden',
    transition: 'all .25s ease',
  } as const);

export const content = (isMobile: boolean) =>
  ({
    height: '100%',
    overflowY: 'auto',
    order: isMobile ? -1 : 1,
  } as const);
