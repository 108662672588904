import axios from 'axios';
import { LocalStorageAPI } from 'services/localStorageService/localStorage.service';

export const privateAdapter = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

privateAdapter.interceptors.request.use(config => {
  if (!config.headers) return config;
  config.headers['Authorization'] = `Bearer ${LocalStorageAPI.getAccessToken()}`;
  return config;
});

privateAdapter.interceptors.response.use(
  res => res,
  async err => {
    if (err.response.status === 401 || err.response.status === 403) {
      LocalStorageAPI.clearToken();
      window.location.href = '/login';
      return;
    }
    throw err;
  },
);
