import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { LocalStorageAPI } from 'services/localStorageService/localStorage.service';

const PrivateRoute: React.FC = () => {
  const token = LocalStorageAPI.getAccessToken();
  const location = useLocation();
  if (!token) {
    LocalStorageAPI.setRedirection(location.pathname);
  }

  return token ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  );
};

export default PrivateRoute;
