class LocalStorageService {
  public setToken = (tokenObj: { access_token: string; refresh_token: string }) => {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  };
  public getAccessToken = (): string | null => {
    return localStorage.getItem('access_token');
  };
  public getRefreshToken = (): string | null => {
    return localStorage.getItem('refresh_token');
  };
  public clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };
  public setRedirection = (pathname: string) => {
    localStorage.setItem('redirection', pathname);
  };
  public clearRedirection = () => {
    localStorage.removeItem('redirection');
  };
}

export const LocalStorageAPI = new LocalStorageService();
