import { createTheme } from '@mui/material/styles';

export const dangerRed = {
  main: '#d32f2f',
};

export const purple = {
  main: '#896DA0',
};

//todo: update to a separate grey with 100s scale
export const disabled = {
  backgroundDark: '#E4E4E4',
  backgroundLight: '#F7F7F7',
  backgroundExtraLight: '#FBFBFB',
  fontColor: '#AFAFAF',
};

export const green = {
  transparentLight: '#C2D8C0',
  light: '#A8C7A5',
  main: '#307B28',
  dark: '#004e00',
};

export const red = {
  light: '#EED3CE',
  main: '#D88679',
  dark: '#d32f2f',
};

export const grey = {
  transparentLight: '#899BAA',
  light: '#AAB6BD',
  main: '#587386',
  // dark: "#b7bec7",
};

export const pink = {
  main: '#AC3A64',
};

export const blue = {
  light: '#C4CDD4',
  main: '#4D64B0',
};

export const white = {
  main: '#FFF',
};

export const fontSizes = {
  extraSmall: '12px',
  small: '14px',
  main: '16px',
  large: '20px',
  extraLarge: '26px',
};

export const lineHeights = {
  extraSmall: '16px',
  small: '18px',
  main: 1.2,
  large: '24px',
  extraLarge: '26px',
};

export const fontWeights = {
  light: '300',
  regular: '500',
  semiBold: '600',
};

export const fontFamilyTypes = {
  light: 'Poppins',
  regular: 'Poppins',
  semiBold: 'Poppins',
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 4,
  palette: {
    // type: "light",
    // primaryText: "#587386",
    // background: "#e9f0fa",
    // primaryButton: "#307B28",
    // purple: "#896DA0",
    // yellow: "#EABA00",
    // pink: "#AC3A64",
    // blue: "#4D64B0",
    primary: green,
    secondary: grey,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    // useNextVariants: true
    htmlFontSize: 10,
    body1: {
      fontFamily: fontFamilyTypes.light,
      fontWeight: fontWeights.light,
      lineHeight: lineHeights.main,
      fontSize: fontSizes.main,
      color: grey.main,
    },
    body2: {
      fontFamily: fontFamilyTypes.light,
      fontWeight: fontWeights.light,
      lineHeight: lineHeights.large,
      fontSize: fontSizes.small,
      color: grey.main,
    },
    h3: {
      fontFamily: fontFamilyTypes.semiBold,
      fontWeight: fontWeights.semiBold,
      lineHeight: lineHeights.large,
      fontSize: fontSizes.extraLarge,
      color: grey.main,
    },
    h4: {
      fontFamily: fontFamilyTypes.light,
      fontWeight: fontWeights.light,
      lineHeight: lineHeights.extraLarge,
      fontSize: fontSizes.large,
      color: grey.main,
    },
    h5: {
      fontFamily: fontFamilyTypes.semiBold,
      fontWeight: fontWeights.semiBold,
      lineHeight: lineHeights.large,
      fontSize: fontSizes.large,
      color: grey.main,
    },
    h6: {
      fontFamily: fontFamilyTypes.semiBold,
      fontWeight: fontWeights.semiBold,
      lineHeight: lineHeights.main,
      fontSize: fontSizes.main,
      color: grey.main,
    },
    subtitle1: {
      fontFamily: fontFamilyTypes.semiBold,
      fontWeight: fontWeights.semiBold,
      lineHeight: lineHeights.small,
      fontSize: fontSizes.small,
      color: grey.main,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 10,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '75vw',
          maxWidth: '325px',
          marginBottom: '8px',
          fontFamily: fontFamilyTypes.regular,
          fontSize: fontSizes.main,
          lineHeight: 1.2,
          fontWeight: fontWeights.regular,
          boxSizing: 'border-box',
          display: 'inline-flex',
          WebkitBoxAlign: 'center',
          alignItems: 'center',
        },
        input: {
          color: grey.main,
          fontSize: '20px',
          font: 'inherit',
          letterSpacing: 'inherit',
          border: '0px',
          boxSizing: 'content-box',
          background: 'none',
          height: '1.4375em',
          margin: '0px',
          WebkitTapHighlightColor: 'transparent',
          display: 'block',
          minWidth: '0px',
          width: '100%',
          animationName: 'mui-auto-fill-cancel',
          animationDuration: '10ms',
          padding: '16.5px 14px',

          '&.Mui-disabled': {
            color: disabled.fontColor,
          },
          '&:-webkit-autofill': {
            '&, &:hover, &:focus': {
              WebkitTextFillColor: grey.main,
              transition: 'background-color 5000s ease-in-out 0s', // transparent background hack taken from https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
            },
          },
        },
      },
    },
    MuiInput: {},
    MuiFormLabel: {},
    MuiFormControl: {},
    MuiFormControlLabel: {},
    MuiRadio: {},
    MuiMenuItem: {},
    MuiPaper: {},
    MuiDialog: {},
    MuiDialogTitle: {},
    MuiDialogContent: {},
    MuiStepLabel: {},
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          height: 26,
          fontSize: 14,
        },
        root: {
          height: '46px',
          px: 28,
          borderRadius: '23px',
          '&:hover': {
            opacity: 1,
          },
          '&:active': {
            opacity: 1,
          },
          '&.Mui-disabled': {
            border: 'transparent',
          },
          fontFamily: fontFamilyTypes.light,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: fontWeights.light,
          textTransform: 'none',
          letterSpacing: '0px',
          '&.MuiButton-text': {
            color: grey.main,
            fontWeight: fontWeights.regular,
            textDecoration: 'underline',
          },
        },
        contained: {
          border: '1px solid #457934',
          backgroundColor: '#457934',
          color: 'white',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#FFF',
            color: '#457934',
            opacity: 1,
          },
          '&:active': {
            backgroundColor: '#457934',
            color: '#FFF',
            opacity: 1,
          },
        },
        text: {
          margin: 0,
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

theme.shadows[1] = '3px 3px 5px 0 #00000029';
theme.shadows[2] = '3px 3px 25px 0 #00000029';

export default theme;
