import { User } from 'models/user/User';
import { CollectAnswerPayload } from 'store/userSlice/thunk/collectAnswerThunk';
import { privateAdapter } from 'utils/axios/privateAxios';

class UserService {
  public getUser = async () => {
    const res = await privateAdapter.get('/users');
    return res.data.result;
  };

  public updateUser = async (body: User) => {
    const res = await privateAdapter.patch('/users', body);
    return res.data.result;
  };

  public getCombinedResults = async (body: any) => {
    const res = await privateAdapter.get('/questionnaire/questionnaires/answers', body);
    return res.data.result;
  };

  public collectAnswer = async (body: CollectAnswerPayload) => {
    const res = await privateAdapter.post('/questionnaire/questionnaires/app/answers', body);
    return res.data.result;
  };

  public getUserAccount = async () => {
    const res = await privateAdapter.get('/users/account');
    return res.data.result;
  };

  public getUserQuestion = async () => {
    const res = await privateAdapter.get('/users/account/reminder', {
      params: { timeZone: new Date().getTimezoneOffset() },
    });
    return res.data.result;
  };
}

export const UserAPI = new UserService();
