import { createAsyncThunk } from '@reduxjs/toolkit';
import { Factors, Question } from 'models/story/Story';
import { UserAPI } from 'services/userService/user.service';

export const collectAnswerThunk = createAsyncThunk(
  'user/collectAnswerThunk',
  async (
    data: { answer: number | string; question: Question; nextQuestionId?: string; metricTag: string },
    thunkAPI,
  ) => {
    const state: any = thunkAPI.getState();
    const body = {
      answer: data.answer,
      questionId: data.question.id,
      question: data.question.title,
      questionImportance: data.question.questionImportance,
      factors: data.question.factors,
      maxValue: data.question.answers.length,
      oneTime: Boolean(data.question.oneTime),
      nextQuestionId: data.nextQuestionId,
    };

    try {
      const response = await UserAPI.collectAnswer(body);
      const questions = state.metric.data[data.metricTag]!.questions;

      return {
        nextQuestion: response.nextQuestion,
        metricTag: data.metricTag,
        questionIndex: questions.findIndex((q: any) => q.id === data.question.id),
        questionsLength: questions?.length,
      };
    } catch (e: any) {
      throw e.response.data.message ? e.response.data.message : 'Something went wrong while updating your details...';
    }
  },
);

export const collectUserPopupAnswerThunk = createAsyncThunk(
  'user/collectAnswerThunk',
  async (data: CollectAnswerPayload) => {
    try {
      return await UserAPI.collectAnswer(data);
    } catch (e: any) {
      throw e.response.data.message ? e.response.data.message : 'Something went wrong while updating your details...';
    }
  },
);

export interface CollectAnswerPayload {
  answer: string | number;
  questionId: string;
  question: string;
  questionImportance: number;
  factors: Factors;
  maxValue: number;
  oneTime: boolean;
}
