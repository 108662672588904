import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { disabled, fontFamilyTypes, fontSizes, fontWeights, grey, lineHeights, white } from 'theme/theme';

const useStyles = makeStyles({
  group: {
    display: 'flex',
    flexDirection: 'row',
    '& label:nth-child(2)': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    marginLeft: '16px',
  },
  label: {
    margin: '0 -10px',
    '& span:nth-child(1)': {
      display: 'none',
    },
    '& span:nth-child(2)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',

      fontSize: fontSizes.main,
      lineHeight: lineHeights.extraSmall,
      fontFamily: fontFamilyTypes.regular,
      border: '1px solid #E4E4E4',
      borderRadius: '15px',
      padding: '12px 20px',
      margin: '0 -10px',
      '&.Mui-disabled': {
        color: disabled.fontColor,
        fontSize: fontSizes.small,
        fontFamily: fontFamilyTypes.light,
        fontWeight: fontWeights.light,
      },
    },
    '& .MuiRadio-colorPrimary.Mui-checked + span': {
      backgroundColor: grey.main,
      color: white.main,
      fontFamily: fontFamilyTypes.semiBold,
      fontWeight: fontWeights.semiBold,
      zIndex: 1,
      '&.Mui-disabled': {
        backgroundColor: disabled.backgroundDark,
      },
    },
  },
});

export const StyledToggle = ({ label, id, name, value, onChange, defaultValue, options, disabled }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        className={classes.group}
      >
        {options.map((item, i) => (
          <FormControlLabel
            value={item}
            control={<Radio disableRipple />}
            label={item}
            key={i}
            disabled={disabled}
            className={classes.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
