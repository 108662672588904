import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config, Notification } from '../../models/app/App';
import { getConfigThunk } from './thunk/getConfigThunk';

export interface AppState {
  loading: boolean;
  config: Config;
  notification: Notification;
}

const initialState: AppState = {
  loading: false,
  config: {} as Config,
  notification: {
    status: 'success',
    message: '',
    open: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotification: (state, action: PayloadAction<Omit<Notification, 'open'>>) => {
      state.notification = { ...action.payload, open: true };
    },
    closeNotification: state => {
      state.notification.open = false;
    },
  },
  extraReducers: {
    [getConfigThunk.fulfilled.type]: (state, action) => {
      state.config = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingAction, setNotification, closeNotification } = appSlice.actions;

export default appSlice.reducer;
