import { makeStyles } from '@mui/styles';
import theme, { blue } from 'theme/theme';

export const useStyles = makeStyles({
  navList: {
    '& [role="button"]': {
      padding: '18px 10px 18px 0',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'start',
    width: '100%',
    borderBottom: `1px solid ${blue.light}`,
    '& img': {
      margin: '0 16px 0 24px',
      width: '20px',
      height: '24px',
    },
    '& span': {
      fontWeight: 400,
    },
  },
  insightsLinkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  icon: {
    fontSize: '12px',
    margin: '0 5px 0 0',
    verticalAlign: 'center',
    color: theme.palette.primary.main,
  },
  insightsText: {
    color: theme.palette.primary.main,
  },
});
