import fetchService, { IFetchService } from 'services/fetchService/fetch.service';
import { welcomeQuery } from './welcome.query';

class WelcomeService {
  private _fetchService: IFetchService;

  constructor(fetchService: IFetchService) {
    this._fetchService = fetchService;
  }

  public getItemsAsync = async (): Promise<any> => {
    const res = await this._fetchService.sendAsync(welcomeQuery);

    return res.data;
  };
}

const welcomeService = new WelcomeService(fetchService);

export default welcomeService;
