import { Question } from 'models/story/Story';

interface Recommendation {
  title: string;
  subTitle: string;
  description: string;
  metric: string;
  image: {
    width: number;
    height: number;
    url: string;
    title: string;
  };
}

export enum ChartTypes {
  Bars = 'bars',
  StackedBars = 'stacked_bars',
  Trend = 'trend',
  Percentage = 'percentage',
  Percentile = 'percentile',
  DailyPeaks = 'daily_peaks'
}

export enum SectionTypes {
  User = 'user',
  Segment = 'segment',
}

export interface Metric {
  charts: {
    [value in SectionTypes as string]: any[];
  };
  recommendations: Recommendation[];
  questions: Question[];
}
