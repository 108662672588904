import React from 'react';

const Insights = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-24.443 -437.378)">
      <path
        d="M5159.32,3463.909s7.012,1.714,13.684-8.587,9.475-7.628,9.475-7.628v19.519H5159.32Z"
        transform="translate(-5134.068 -3006.531)"
        className={'background'}
      />
      <path
        d="M3.5,22.419v2.176H26V26H2V2H3.5V21.513c3.732,0,9.563-3.51,12.175-7.83,3.732-6,5.844-8.327,10.323-8.327v.917c-3.483,0-6.231,2.645-9.465,7.925C13.466,20.189,5.536,22.642,3.5,22.419Z"
        transform="translate(22.443 435.378)"
        className={'fill'}
      />
    </g>
  </svg>
);

export default Insights;
