import { useEffect, useMemo } from 'react';
import { LocalStorageAPI } from 'services/localStorageService/localStorage.service';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getUserThunk } from 'store/userSlice/thunk/getUserThunk';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'query-string';
import { userSelect } from 'store/userSlice/selectors/userSelectors';
import { getAccountThunk } from 'store/userSlice/thunk/getAccountThunk';
import { getUserQuestionThunk } from 'store/userSlice/thunk/getUserQuestionThunk';

export const useLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(userSelect);
  const token = useMemo(() => {
    return LocalStorageAPI.getAccessToken();
  }, []);

  useEffect(() => {
    const p = qs.parse(location.search);
    if (p.utm_source) localStorage.setItem('utm_source', p.utm_source as string);
    if (p.utm_campaign) localStorage.setItem('utm_campaign', p.utm_campaign as string);
    if (p.utm_medium) localStorage.setItem('utm_medium', p.utm_medium as string);
    if (p.utm_term) localStorage.setItem('utm_term', p.utm_term as string);
    if (p.utm_content) localStorage.setItem('utm_content', p.utm_content as string);
  }, [location.search]);

  useEffect(() => {
    if (!!user && !user.not_first_time) {
      navigate('/tutorial');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!token || user) return;
    dispatch(getUserThunk());
    dispatch(getUserQuestionThunk());
    dispatch(getAccountThunk());
  }, [token, dispatch, user]);
};
