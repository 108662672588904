import React from 'react';
import { Badge, Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import * as styles from './NavPanel.styles';
import Dashboard from './assets/Dashboard';
import Insights from './assets/Insights';
import Person from './assets/Person';

const LinkWrap = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  const theme = useTheme();

  return (
    <Box sx={styles.link(Boolean(match))}>
      <Link style={{ textDecoration: match ? 'underline' : 'none' }} to={to} {...props}>
        {children}
      </Link>
      <Box sx={styles.marker(theme, Boolean(match))} />
    </Box>
  );
};

const NavPanel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack spacing={10} direction={isMobile ? 'row' : 'column'}>
      <LinkWrap to={'/metrics'}>
        <IconButton>
          <Dashboard />
        </IconButton>
      </LinkWrap>
      <LinkWrap to={'/insights'}>
        <IconButton>
          <Badge badgeContent={5} color="primary">
            <Insights />
          </Badge>
        </IconButton>
      </LinkWrap>
      <LinkWrap to={'/account'}>
        <IconButton>
          <Person />
        </IconButton>
      </LinkWrap>
    </Stack>
  );
};

export default NavPanel;
