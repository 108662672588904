import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'start',
    '&:last-child': {
      marginBottom: 0,
    },
    '& div:last-child': {
      textAlign: 'end',
    },
  },
  content: {
    paddingLeft: '8px',
    minWidth: '60%',
    alignSelf: 'end',
  },
});

export const LabelAndContent = ({
  label,
  content,
  withColon = true,
  className = '',
  styleLabel = { alignSelf: 'center' },
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.label}>
        <Typography variant="body2" style={styleLabel}>
          {label}
          {withColon && ':'}
        </Typography>
      </div>
      <div className={classes.content}>
        {typeof content === 'string' || typeof content === 'number' ? (
          <Typography variant="body2">{content}</Typography>
        ) : typeof content === 'object' && content.length ? (
          content.map((text, i) => (
            <Typography variant="body2" key={i}>
              {text}
            </Typography>
          ))
        ) : (
          content
        )}
      </div>
    </div>
  );
};
