import { createAsyncThunk } from '@reduxjs/toolkit';
import welcomeApi from 'services/welcomeService/welcome.service';

export const getWelcomeThunk = createAsyncThunk('welcome/getWelcomeThunk', async _thunkAPI => {
  try {
    const res = await welcomeApi.getItemsAsync();

    return res.welcomePageCollection.items[0] || null;
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something wrong...';
  }
});
