import { useEffect, useState } from 'react';
import { useAppSelector } from './useRedux';

export const useHasGarminConnected = () => {
  const user = useAppSelector(state => state.user.user);
  const [hasGarminConnected, setHasGarminConnected] = useState(false);

  useEffect(() => {
    if (user?.oauth_tokens) {
      const garminConnection = user.oauth_tokens.filter(token => token.provider === 'garmin').length;
      setHasGarminConnected(!!garminConnection);
    }
  }, [user?.oauth_tokens]);

  return hasGarminConnected;
};
