import React, { useState } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { Burger } from './components/Burger';
import { Link } from 'react-router-dom';
import { DrawerMenu } from './components/DrawerMenu';
import { useAppSelector } from 'hooks/useRedux';

const Header: React.FC = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [showConnectWatch, setShowConnectWatch] = useState(false);
  const user = useAppSelector(state => state.user.user);

  const closeDrawer = () => {
    setShowConnectWatch(false);
    setIsOpenDrawer(!isOpenDrawer);
  };

  return (
    <>
      <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: '#fff', zIndex: 2 }}>
        <Toolbar>
          {user && <Burger open={isOpenDrawer} setOpen={closeDrawer} />}
          <Box component="div" sx={{ flexGrow: 1, mt: '8px', mb: '8px', textAlign: 'center' }}>
            <Link to="/">
              <img src={'/images/myairlogo.png'} width="100px" alt="myAir logo" style={{ verticalAlign: 'bottom' }} />
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerMenu
        isOpen={isOpenDrawer}
        showConnectWatch={showConnectWatch}
        closeDrawer={closeDrawer}
        setShowConnectWatch={setShowConnectWatch}
      />
    </>
  );
};

export default Header;
