import { makeStyles } from '@mui/styles';
import { grey } from 'theme/theme';

export const useStyles = makeStyles({
  nameAndEmail: {
    marginBottom: '18px',
    '& p': {
      width: '100%',
      fontWeight: 400,
      overflowWrap: 'break-word',
    },
  },
  email: {
    display: 'flex',
    justifyContent: 'space-between',
    color: grey.main,

    paddingRight: '22px',

    '& p': {
      verticalAlign: 'middle',
    },
  },
});
