import { privateAdapter } from 'utils/axios/privateAxios';
import { ResponseGetMetricsDTO } from 'models/dashboard/Dashboard';
import { SingleMetricDTO } from './models/SingleMetricDTO';
import { SingleChartDTO } from './models/SingleChartDTO';
import { Metric } from 'models/metric/Metric';
import { Response } from 'models/common/Response';

class DashboardService {
  public getMetrics = async () => {
    const { data } = await privateAdapter.get<Response<ResponseGetMetricsDTO>>('users/metrics');

    return data.result;
  };

  public getSingleMetric = async ({ type, slug, period }: SingleMetricDTO) => {
    const { data } = await privateAdapter.get<{ success: boolean; result: Metric }>(
      `users/metrics/${type}?submetric=${slug}&period=${period}`,
    );

    return data.result;
  };

  public getSingleChart = async ({ type, subMetric, chart, period, compareTo }: SingleChartDTO) => {
    const { data } = await privateAdapter.get<{ success: boolean; result: any }>(
      `users/metrics/${type}/${chart}?submetric=${subMetric}&period=${period}&segment=${compareTo}`,
    );

    return data.result;
  };
}

export const dashboardService = new DashboardService();
