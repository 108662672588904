import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { fontSizes } from 'theme/theme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    padding: '10px 0',
  },
  container: {
    cursor: 'pointer',
    width: '100%',
    height: '74px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '15px',
  },
  imageAndTitle: {
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    width: 40,
    marginRight: 5,
    '& img': {
      width: '100%',
    },
  },
  arrow: {
    margin: '24px 16px 24px 8px',
    fontSize: fontSizes.extraLarge,
    [theme.breakpoints.down(280)]: {
      marginLeft: '8px',
    },
  },
}));

export const TabSelector = ({ title, icon, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.container} onClick={onClick}>
        <div className={classes.imageAndTitle}>
          {!!icon && (
            <div className={classes.image}>
              <img src={icon} alt={`${title} icon`} />
            </div>
          )}
          <Typography variant="body1">{title}</Typography>
        </div>
        <Typography variant="body1" className={classes.arrow}>
          {'>'}
        </Typography>
      </Paper>
    </div>
  );
};
