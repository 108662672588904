export interface MostStress {
  mostValue: string;
  mostUnits: string;
  mostPercentValue: number;
  icon: Icon;
  subChartTitle: string;
}

export interface Button {
  text: string;
  action: string;
  fillColor: string;
  lineColor: string;
}

export interface Icon {
  width: number;
  height: number;
  url: string;
}

export interface Metric {
  text: string;
  action: string;
  fillColor: string;
  lineColor: string;
  slug: string;
}

export interface Category {
  date: string;
  mostStress?: MostStress | null;
  value?: number;
  units: string | null;
  comparedValue: null | string;
  title: string;
  type: string;
  belowChartDataType?: any;
  button: Button | null;
  chartType: 'Circle' | 'Line';
  chartColor: string;
  metricName: string | null;
  diffPeriod: string;
  compareTo: string;
  lowMetrics: Metric[] | null;
  isNeedSync: boolean;
  source: CategorySource;
  notification: {
    description: string;
    title: string;
    type: CategoryNotificationType;
  };
  pageTitle: string | null;
}

export enum CategorySource {
  Physiological = 'physiological',
  Cognitive = 'cognitive',
}

export enum CategoryNotificationType {
  NOT_ANSWERED_QUESTIONS = 'not_answered_questions',
  NOT_SYNCED_WATCH = 'not_synced_watch',
  NOT_WEARING_WATCH = 'not_wearing_watch',
}

export interface QuestionnaireLink {
  url: string;
  text: string | null;
  type: string;
  image: string | null;
}

export interface ResponseGetMetricsDTO {
  metrics: Category[];
  questionnaireLink: QuestionnaireLink | null;
  effectsExplanation: string | null;
  effectsSubtitle: string | null;
  effectsTitle: string | null;
  stressTitle: string | null;
  pageTitle: string | null;
}

export enum Period {
  Week = 'week',
  Month = 'month',
}
