import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoriesAPI } from 'services/stroriesService/stories.service';

export const getStoriesThunk = createAsyncThunk(
  'metrics/getStoriesThunk',
  async (
    queries: {
      queryStories: string;
      queryQuestions: string;
    },
    _thunkAPI,
  ) => {
    try {
      const res = await StoriesAPI.getStories(queries.queryStories, queries.queryQuestions);
      if (!res.stories.length) {
        window.location.pathname = '/account';
        return { stories: [], insights: [] };
      }
      return res;
    } catch (e: any) {
      throw e.response.data.message ? e.response.data.message : 'Something wrong...';
    }
  },
);
