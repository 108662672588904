import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStoriesThunk } from './thunk/getStoriesThunk';
import { InsightCard, StoriesDTO, Story } from 'models/story/Story';

export interface StoriesState {
  stories: Story[];
  loading: boolean;
  error: string;
  cognitiveStory: any;
  insights: InsightCard[];
  visited: boolean;
}

const initialState: StoriesState = {
  stories: [],
  insights: [],
  loading: true,
  error: '',
  cognitiveStory: {},
  visited: false,
};

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStoriesVisitedAction: (state, action: PayloadAction<boolean>) => {
      state.visited = action.payload;
    },
  },
  extraReducers: {
    [getStoriesThunk.pending.type]: (state, _action) => {
      state.loading = true;
    },
    [getStoriesThunk.fulfilled.type]: (state, action: PayloadAction<StoriesDTO>) => {
      state.stories = action.payload.stories;
      state.insights = action.payload.insights;
      state.loading = false;
      state.error = '';
    },
    [getStoriesThunk.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStoriesVisitedAction } = storiesSlice.actions;

export default storiesSlice.reducer;
