import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { makeStyles, withStyles } from '@mui/styles';
import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { fontSizes, fontWeights, lineHeights, white } from 'theme/theme';
import { disabled } from 'theme/theme';

const Connector = withStyles(theme => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50%)',
    [theme.breakpoints.down(350)]: {
      left: 'calc(-55%)',
    },
    right: 'calc(50%)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.primary.light,
    borderTopWidth: 2,
  },
}))(StepConnector);

const useStepIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.light,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 9,
    height: 9,
    borderRadius: '50%',
    backgroundColor: white.main,
    border: `2px solid ${theme.palette.primary.light}`,
    zIndex: 1,
  },
  completed: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    zIndex: 1,
    width: 9,
    height: 9,
    borderRadius: '50%',
  },
}));

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed || active ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    '& .MuiStepLabel-label': {
      fontSize: fontSizes.small,
      lineHeight: lineHeights.small,
      [theme.breakpoints.down(370)]: {
        fontSize: fontSizes.extraSmall,
        lineHeight: lineHeights.extraSmall,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: '4px',
      fontWeight: fontWeights.light,
      color: disabled.fontColor,
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(400)]: {
      padding: 0,
    },
  },
}));

export const StyledSteps = ({ steps = [], activeStep, date }) => {
  const classes = useStyles();
  const updatedSteps = !!steps?.length
    ? steps
    : [
        `${moment(date).isAfter() ? 'Next Order' : 'Ordered'}`,
        'Fulfilled',
        'In Transit',
        `${activeStep === 3 ? 'Delivered' : 'Delivery'}`,
      ];
  const getPostfix = label => {
    switch (label) {
      case 'Delivery':
      case 'Delivered':
        return activeStep > 0 && date ? ' ' + moment(date).format('MM/DD/YYYY') : '';
      case 'Next Order':
      case 'Ordered':
        return activeStep <= 0 && date ? ' ' + moment(date).format('MM/DD/YYYY') : '';
      default:
        return '';
    }
  };

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep === -1 ? 0 : activeStep}
      className={classes.root}
      connector={<Connector />}
    >
      {updatedSteps.map((label, i) => (
        <Step key={i} disabled={true}>
          <StepLabel StepIconComponent={StepIcon}>{label + getPostfix(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
