import { Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { grey } from 'theme/theme';

const useStyles = makeStyles({
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    backgroundColor: '#f5f8fa',
    border: `2px solid ${grey.main}`,
  },
  checkedIcon: {
    backgroundColor: '#f5f8fa',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23587386'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
});

interface StyledCheckboxProps {
  checked: boolean;
  onChange: () => void;
  name: string;
}

export const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ checked, onChange, name }) => {
  const classes = useStyles();
  return (
    <Checkbox
      disableRipple
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'myAir checkbox' }}
      name={name}
      checked={checked}
      onChange={onChange}
    />
  );
};
