import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMetrics } from './thunk/getMetrics';
import { Category, QuestionnaireLink, ResponseGetMetricsDTO } from 'models/dashboard/Dashboard';

export interface DashboardState {
  data: Category[];
  questionnaireLink: QuestionnaireLink | null;
  loading: boolean;
  titles: {
    effectsExplanation: string | null;
    effectsSubtitle: string | null;
    effectsTitle: string | null;
    stressTitle: string | null;
  };
  pageTitle: string | null;
}

const initialState: DashboardState = {
  data: [],
  questionnaireLink: null,
  loading: false,
  titles: {
    effectsExplanation: null,
    effectsSubtitle: null,
    effectsTitle: null,
    stressTitle: null,
  },
  pageTitle: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getMetrics.fulfilled.type]: (state, action: PayloadAction<ResponseGetMetricsDTO>) => {
      const { metrics, questionnaireLink, effectsExplanation, effectsSubtitle, effectsTitle, stressTitle, pageTitle } =
        action.payload;

      state.data = metrics;
      state.questionnaireLink = questionnaireLink;
      state.loading = false;
      state.titles = {
        effectsExplanation,
        effectsSubtitle,
        effectsTitle,
        stressTitle,
      };
      state.pageTitle = pageTitle?.length ? pageTitle : null;
    },
    [getMetrics.rejected.type]: (state, _action) => {
      state.loading = false;
    },
    [getMetrics.pending.type]: (state, _action) => {
      state.loading = true;
    },
  },
});

export default dashboardSlice.reducer;
