import React from 'react';

const Person = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25.584" height="24.75" viewBox="0 0 25.584 24.75">
    <g transform="translate(-23.001 -545.207)">
      <g transform="translate(29.997 545.207)" className={'background'}>
        <path
          d="M 5.787108421325684 10.82421970367432 C 3.00963830947876 10.82421970367432 0.7499984502792358 8.564579010009766 0.7499984502792358 5.787109375 C 0.7499984502792358 3.009639263153076 3.00963830947876 0.7499994039535522 5.787108421325684 0.7499994039535522 C 8.564578056335449 0.7499994039535522 10.82421875 3.009639263153076 10.82421875 5.787109375 C 10.82421875 8.564579010009766 8.564578056335449 10.82421970367432 5.787108421325684 10.82421970367432 Z"
          stroke="none"
        />
        <path
          d="M 5.787108421325684 1.499999046325684 C 3.423188209533691 1.499999046325684 1.499998092651367 3.423189163208008 1.499998092651367 5.787109375 C 1.499998092651367 8.151029586791992 3.423188209533691 10.07421970367432 5.787108421325684 10.07421970367432 C 8.151028633117676 10.07421970367432 10.07421875 8.151029586791992 10.07421875 5.787109375 C 10.07421875 3.423189163208008 8.151028633117676 1.499999046325684 5.787108421325684 1.499999046325684 M 5.787108421325684 -9.5367431640625e-07 C 8.983238220214844 -9.5367431640625e-07 11.57421875 2.59097957611084 11.57421875 5.787109375 C 11.57421875 8.98323917388916 8.983238220214844 11.57421970367432 5.787108421325684 11.57421970367432 C 2.590978622436523 11.57421970367432 -1.9073486328125e-06 8.98323917388916 -1.9073486328125e-06 5.787109375 C -1.9073486328125e-06 2.59097957611084 2.590978622436523 -9.5367431640625e-07 5.787108421325684 -9.5367431640625e-07 Z"
          stroke="none"
          className={'fill'}
        />
      </g>
      <path
        d="M21.379,30.054H4.264a3.88,3.88,0,0,1-1.711-.419A3.106,3.106,0,0,1,.841,26.069a11.6,11.6,0,0,1,4.674-6.449,3.1,3.1,0,0,1,3.094-.21,9.584,9.584,0,0,0,8.294,0,3.1,3.1,0,0,1,3.028.157,11.95,11.95,0,0,1,4.674,6.5c.461,1.468-.395,2.936-1.975,3.722A3.033,3.033,0,0,1,21.379,30.054Z"
        transform="translate(23.056 539.153)"
        className={'stroke background'}
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default Person;
