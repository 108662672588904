import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShopAPI } from 'services/shopService/shop.service';

export const getOrdersThunk = createAsyncThunk('shop/getOrdersThunk', async thunkAPI => {
  try {
    return await ShopAPI.getOrders();
  } catch (e: any) {
    throw e.response.data.message
      ? e.response.data.message
      : 'Something went wrong while getting your orders...';
  }
});
