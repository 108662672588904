import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'models/user/User';
import { UserAPI } from '../../../services/userService/user.service';

export const updateUserThunk = createAsyncThunk('user/updateUserThunk', async (body: User) => {
  try {
    return await UserAPI.updateUser(body);
  } catch (e: any) {
    throw e.response.data.message
      ? e.response.data.message
      : 'Something went wrong while updating your details...';
  }
});
