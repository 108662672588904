import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';

const Insights = React.lazy(() => import('containers/Insights'));
const UserAccount = React.lazy(() => import('containers/UserAccount'));
const Questionnaire = React.lazy(() => import('containers/Questionnaire'));
const Login = React.lazy(() => import('containers/Login'));
const Tutorial = React.lazy(() => import('containers/Tutorial'));
const Dashboard = React.lazy(() => import('containers/Dashboard'));

export const AppRoutes = (
  <Suspense fallback={''}>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="metrics" replace />} />
          <Route path="metrics/*" element={<Dashboard />} />
          <Route path="insights" element={<Insights />} />
          <Route path="account" element={<UserAccount />} />
          <Route path="questionnaire/:slug" element={<Questionnaire />} />
          <Route path="tutorial" element={<Tutorial />} />
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </ScrollToTop>
  </Suspense>
);
