export interface IFetchService {
  sendAsync: (query: string) => Promise<any>;
}

class FetchService {
  private readonly uriContentful = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_APP_SPACE_ID}`;

  sendAsync = async (query: string): Promise<any> => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_APP_ACCESS_TOKEN}`,
      },
      body: !!query ? JSON.stringify({ query }) : undefined,
    };

    return await fetch(this.uriContentful, options).then(res => res.json());
  };
}

const fetchService = new FetchService();

export default fetchService;
