import React from 'react';
import { AppRoutes } from 'routes/appRoutes';
import Header from './components/Header';
import { useLayout } from './hooks/useLayout';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NavPanel from './components/NavPanel';
import * as styles from './Layout.styles';
import { Notification } from 'styledComponents';
import { useResizeWindow } from 'hooks/useResizeWindow';

const PAGES_WITH_PANEL = ['metrics', 'insights', 'account'];

const Layout: React.FC = () => {
  const { height } = useResizeWindow();
  useLayout();
  const location = useLocation();
  const isPanelOn = PAGES_WITH_PANEL.some(page => location.pathname.includes(page));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={styles.root(height)}>
      <Header />
      <Box sx={styles.grid(isMobile)}>
        <Box sx={styles.navPanel(isMobile, isPanelOn)}>
          <NavPanel />
        </Box>
        <Box sx={styles.content(isMobile)}>{AppRoutes}</Box>
      </Box>
      <Notification />
    </Box>
  );
};

export default Layout;
