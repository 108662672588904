import React from 'react';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './DrawerMenu.styles';
import { DrawerMenuProps } from './DrawerMenu.types';
import { useDrawerMenu } from './hooks/useDrawerMenu';
import { NameAndEmail } from './components/NameAndEmail';
import { MenuItems } from './components/MenuItems/MenuItems';
import { SmartWatches } from './components/SmartWatches/SmartWatches';

export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  isOpen,
  showConnectWatch,
  closeDrawer,
  setShowConnectWatch,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const { links, data } = useDrawerMenu();

  return (
    <Drawer classes={{ paper: classes.paper }} anchor="left" open={isOpen} onClose={closeDrawer}>
      {/* placeholder for X which comes from Header */}
      <Box component="div" sx={{ minHeight: isMobile ? '40px' : '80px' }} />
      <Box className={classes.detailsWrapper}>
        <NameAndEmail firstName={data?.user?.first_name} lastName={data?.user?.last_name} email={data?.user?.email} />
      </Box>
      <MenuItems links={links} closeDrawer={closeDrawer} newInsightsNum={5} />
      <SmartWatches showConnectWatch={showConnectWatch} setShowConnectWatch={setShowConnectWatch} />
    </Drawer>
  );
};
