import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export const StandardStyledRadio = ({ id, value, onChange, name, label, defaultValue, options }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={label} id={id} name={name} value={value} onChange={onChange} defaultValue={defaultValue}>
        {options.map((item, i) => (
          <FormControlLabel value={item} control={<Radio />} label={item} key={i} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
