import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardService } from 'services/dashboardService/dashboard.service';

export const getMetrics = createAsyncThunk('dashboard/getMetrics', async _thunkAPI => {
  try {
    return await dashboardService.getMetrics();
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something wrong...';
  }
});
