import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMetricThunk } from './thunk/getMetricThunk';
import { Metric } from 'models/metric/Metric';
import { getChartThunk } from './thunk/getChartThunk';
import { collectAnswerThunk } from 'store/userSlice/thunk/collectAnswerThunk';

export interface MetricState {
  data: {
    [key: string]: Metric | null;
  };
  loading: boolean;
}

const initialState: MetricState = {
  data: {},
  loading: false,
};

export const metricSlice = createSlice({
  name: 'metric',
  initialState,
  reducers: {},
  extraReducers: {
    [getMetricThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.data[action.payload.metricTag] = action.payload.data;
      state.loading = false;
    },
    [getMetricThunk.rejected.type]: (state, _action) => {
      state.loading = false;
    },
    [getMetricThunk.pending.type]: (state, _action) => {
      state.loading = true;
    },
    [getChartThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.data[action.payload.metricTag]!.charts[action.payload.section][action.payload.chartIndex].period =
        action.payload.period;
      state.data[action.payload.metricTag]!.charts[action.payload.section][action.payload.chartIndex].data =
        action.payload.data;
      state.loading = false;
    },
    [getChartThunk.rejected.type]: (state, _action) => {
      state.loading = false;
    },
    [getChartThunk.pending.type]: (state, _action) => {
      state.loading = true;
    },
    [collectAnswerThunk.fulfilled.type]: (
      state,
      action: PayloadAction<{
        nextQuestion: any;
        metricTag: string;
        questionIndex: number;
        questionsLength: number;
      }>,
    ) => {
      const { nextQuestion, metricTag, questionIndex, questionsLength } = action.payload;

      if (!!nextQuestion && questionIndex < questionsLength - 1) {
        const nextQuestions = JSON.parse(JSON.stringify(state.data[metricTag]!.questions))

        nextQuestions.splice(questionIndex + 1, 1, nextQuestion);
        state.data[metricTag]!.questions = nextQuestions;
      }
    },
  },
});

export default metricSlice.reducer;
