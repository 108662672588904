import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { disabled, fontFamilyTypes, fontSizes, fontWeights, grey, lineHeights, white } from 'theme/theme';

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginBottom: '0',
    marginRight: '12px',
    '& span:nth-child(1)': {
      display: 'none',
    },
    '& span:nth-child(2)': {
      fontSize: fontSizes.main,
      lineHeight: lineHeights.extraSmall,
      fontFamily: fontFamilyTypes.regular,
      border: '1px solid #E4E4E4',
      borderRadius: '15px',
      width: '48px',
      height: '48px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '12px',
      [theme.breakpoints.down(375)]: {
        marginRight: '4px',
      },
      '&.Mui-disabled': {
        color: disabled.fontColor,
        fontSize: fontSizes.small,
        fontFamily: fontFamilyTypes.light,
        fontWeight: fontWeights.light,
      },
    },
    '&:last-child': {
      marginRight: 0,
      [theme.breakpoints.down(375)]: {
        marginRight: 0,
      },
      '& span': {
        marginRight: 0,
      },
    },
    '& .MuiRadio-colorPrimary.Mui-checked + span': {
      backgroundColor: grey.main,
      color: white.main,
      '&.Mui-disabled': {
        backgroundColor: disabled.backgroundDark,
      },
    },
  },
}));

export const CustomizedSpacedRadio = ({ label, id, name, value, onChange, disabled, defaultValue, options }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        className={classes.group}
      >
        {options.map((item, i) => (
          <FormControlLabel
            value={item}
            control={<Radio disableRipple />}
            label={item}
            key={i}
            disabled={disabled}
            className={classes.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
