import { APP_QUERY } from './query';
import fetchService, { IFetchService } from 'services/fetchService/fetch.service';

class AppService {
  private _fetchService: IFetchService;

  constructor(fetchService: IFetchService) {
    this._fetchService = fetchService;
  }

  getConfig = async () => {
    const res = await this._fetchService.sendAsync(APP_QUERY);

    return res.data.config;
  };
}

export const AppAPI = new AppService(fetchService);
