import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from '../../../services/userService/user.service';

export const getUserThunk = createAsyncThunk('user/getUserThunk', async thunkAPI => {
  try {
    return await UserAPI.getUser();
  } catch (e: any) {
    throw e.response.data.message
      ? e.response.data.message
      : 'Something went wrong while getting your details...';
  }
});
