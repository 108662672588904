import React from 'react';

const Dashboard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-27.676 -247.969)">
      <path
        d="M21.2,25.951H6.8M23.6,5H4.4C3.068,5,2,6.357,2,8.049v17.9C2,27.643,3.068,29,4.4,29H23.6c1.332,0,2.4-1.357,2.4-3.049V8.049C26,6.357,24.932,5,23.6,5M21.2,19.854H6.8V22.9H21.2V19.854M14,10.707h7.2V7.658H14v3.049m7.2,3.049H14V16.8h7.2Z"
        transform="translate(25.676 242.969)"
        className={'background'}
      />
      <path
        d="M22.393,28.25H4.107A2.618,2.618,0,0,1,2.04,27.185a3.9,3.9,0,0,1-.79-2.41V7.725a3.9,3.9,0,0,1,.79-2.41A2.618,2.618,0,0,1,4.107,4.25H22.393A2.618,2.618,0,0,1,24.46,5.315a3.9,3.9,0,0,1,.79,2.41v17.05a3.9,3.9,0,0,1-.79,2.41A2.618,2.618,0,0,1,22.393,28.25ZM4.107,5.393c-.945,0-1.714,1.046-1.714,2.332v17.05c0,1.286.769,2.332,1.714,2.332H22.393c.945,0,1.714-1.046,1.714-2.332V7.725c0-1.286-.769-2.332-1.714-2.332Zm16,17.05H6.393a.571.571,0,0,1-.571-.571v-2.9a.571.571,0,0,1,.571-.571H20.107a.571.571,0,0,1,.571.571v2.9A.571.571,0,0,1,20.107,22.443ZM6.964,21.3H19.536V19.539H6.964Zm13.143-4.664H13.25a.571.571,0,0,1-.571-.571v-2.9a.571.571,0,0,1,.571-.571h6.857a.571.571,0,0,1,.571.571v2.9A.571.571,0,0,1,20.107,16.636Zm-6.286-1.143h5.714V13.732H13.821Zm6.286-4.664H13.25a.571.571,0,0,1-.571-.571v-2.9a.571.571,0,0,1,.571-.571h6.857a.571.571,0,0,1,.571.571v2.9A.571.571,0,0,1,20.107,10.828ZM13.821,9.685h5.714V7.925H13.821Z"
        transform="translate(26.426 243.719)"
        className={'fill'}
      />
      <path
        d="M-22908.34,14242.547h6.475v10.206h-6.475Z"
        transform="translate(22940.102 -13992.078)"
        className={'fill'}
      />
      <path
        d="M29.031,271.027H44.174"
        transform="translate(3.137 -2.951)"
        fill="none"
        className={'stroke'}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default Dashboard;
