import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from 'services/userService/user.service';

export const getAccountThunk = createAsyncThunk('user/getAccount', async _thunkAPI => {
  try {
    return await UserAPI.getUserAccount();
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something went wrong while updating your details...';
  }
});
