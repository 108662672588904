import { privateAdapter } from 'utils/axios/privateAxios';
import { StoriesDTO } from 'models/story/Story';

class StoriesService {
  public getStories = async (queryStories: string, queryQuestions: string): Promise<StoriesDTO> => {
    const res = await privateAdapter.get('/stories', {
      params: { stories: queryStories, questions: queryQuestions, web: true, timeZone: new Date().getTimezoneOffset() },
    });
    return res.data.result;
  };
}

export const StoriesAPI = new StoriesService();
