import { useDispatch } from 'react-redux';
import { LocalStorageAPI } from 'services/localStorageService/localStorage.service';
import { useAppSelector } from 'hooks/useRedux';
import { setUserAction } from 'store/userSlice/userSlice';
import insightsIcon from 'assets/insights_icon.svg';
import manageSubscriptionIcon from 'assets/person_filled_icon.svg';
import logoutIcon from 'assets/logout.svg';
import researchIcon from 'assets/research.svg';
import ingredientsIcon from 'assets/ingredients.svg';
import dashboardIcon from 'assets/dashboard-icon.svg';

export const useDrawerMenu = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(state => state.user.user);

  const logoutHandler = () => {
    dispatch(setUserAction(null));
    LocalStorageAPI.clearToken();
    LocalStorageAPI.clearRedirection();
    window.location.reload();
  };

  let links: any[] = [
    {
      title: 'Dashboard',
      link: '/',
      icon: dashboardIcon,
      // TODO: updated API
      // disabled: !data?.stressEffects?.length && !data?.stressSummary?.length,
    },
    {
      title: '5 Daily Insights',
      link: '/insights',
      icon: insightsIcon,
      // TODO: updated API
      // disabled: !data?.stressSummary?.length,
    },
    {
      title: 'Subscription Management',
      link: '/account',
      icon: manageSubscriptionIcon,
    },
    {
      title: 'Research',
      link: `${process.env.REACT_APP_BASE_URL_MOLA}/research`,
      externalUrl: true,
      icon: researchIcon,
    },
    {
      title: 'Ingredients',
      link: `${process.env.REACT_APP_BASE_URL_MOLA}/ingredients`,
      externalUrl: true,
      icon: ingredientsIcon,
    },
    {
      title: 'Logout',
      link: '/',
      action: logoutHandler,
      icon: logoutIcon,
    },
  ];

  if (user?.utm_source === 'hr') {
    links = links.filter(item => item.link !== '/account');
  }

  return {
    data: {
      user,
    },
    links,
  };
};
