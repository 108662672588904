import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardService } from 'services/dashboardService/dashboard.service';
import { SingleMetricDTO } from 'services/dashboardService/models/SingleMetricDTO';
import { Period } from 'models/dashboard/Dashboard';
import { SectionTypes } from 'models/metric/Metric';

export const getMetricThunk = createAsyncThunk(
  'metric/getData',
  async ({ type, slug = '', period = Period.Week }: SingleMetricDTO, _thunkAPI) => {
    try {
      const result = await dashboardService.getSingleMetric({ type, slug, period });

      return {
        metricTag: `${type}/${slug}`,
        data: {
          ...result,
          charts: {
            [SectionTypes.User]: result.charts[SectionTypes.User]?.filter((item: any) => !!item) || [],
            [SectionTypes.Segment]: result.charts[SectionTypes.Segment]?.filter((item: any) => !!item) || [],
          },
        },
      };
    } catch (e: any) {
      throw e.response.data.message ? e.response.data.message : 'Something wrong...';
    }
  },
);
