import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from '../../../services/userService/user.service';

export const getUserQuestionThunk = createAsyncThunk('user/getUserQuestionThunk', async (_, _thunkAPI) => {
  try {
    return await UserAPI.getUserQuestion();
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something went wrong while getting your details...';
  }
});
