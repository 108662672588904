import { Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './NameAndEmail.styles';

interface NameAndEmailProps {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const NameAndEmail: React.FC<NameAndEmailProps> = ({ firstName, lastName, email }) => {
  const classes = useStyles();
  return (
    <div className={classes.nameAndEmail}>
      <Typography variant="body1">{`${firstName || ''} ${lastName || ''}`}</Typography>
      <div className={classes.email}>
        <Typography variant="body1">{email || ''}</Typography>
      </div>
    </div>
  );
};
