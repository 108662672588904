import React, { FC } from 'react';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import Layout from './containers/Layout';

const App: FC = () => {
  return <Layout />;
};

export default App;
