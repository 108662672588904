import { privateAdapter } from 'utils/axios/privateAxios';

class ShopService {
  public getOrders = async () => {
    const res = await privateAdapter.get('/shop/orders');
    return res.data.result;
  };

  public skipNextOrder = async (reason: string) => {
    const body = { cancellationReason: reason };
    const res = await privateAdapter.post('/shop/subscription/skip', body);
    return res.data.result;
  };

  public changeProductVariant = async (firstColor: string, secondColor: string, taste: string) => {
    const body = { firstColor, secondColor, taste };
    const res = await privateAdapter.post('/shop/subscription/changeProductVariant', body);
    return res.data.result;
  };

  public changeChargeDate = async (date: Date) => {
    const body = { next_charge_date: date };
    const res = await privateAdapter.post('/shop/subscription/changeChargeDate', body);
    return res.data.result;
  };

  public cancelSubscription = async (reason: string) => {
    const body = { cancellationReason: reason };
    const res = await privateAdapter.post('/shop/subscription/cancel', body);
    return res.data.result;
  };

  public getClientSecret = async () => {
    const res = await privateAdapter.post('/shop/setupPayment');
    return res.data.result;
  };

  public completeSetupPayment = async () => {
    const res = await privateAdapter.post('/shop/completeSetupPayment');
    return res.data.result;
  };
}

export const ShopAPI = new ShopService();
