import { Theme } from '@mui/material';

const COLORS = {
  ACTIVE: '#587386',
  ACTIVE_BACKGROUND: '#c4ced5',
  DEFAULT: '#707070',
  DEFAULT_BACKGROUND: '#e8e8e8',
};

export const link = (isActive: boolean) =>
  ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .background': {
      fill: isActive ? COLORS.ACTIVE_BACKGROUND : COLORS.DEFAULT_BACKGROUND,
    },
    '& .stroke': {
      stroke: isActive ? COLORS.ACTIVE : COLORS.DEFAULT,
    },
    '& .fill': {
      fill: isActive ? COLORS.ACTIVE : COLORS.DEFAULT,
    },
    '& .MuiBadge-badge': {
      background: '#ac3a64',
    },
  } as const);

export const marker = (theme: Theme, match: boolean) =>
  ({
    width: 8,
    height: 8,
    borderRadius: 4,
    background: theme.palette.secondary.main,
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: match ? 'block' : 'none',
    mt: -1.5,
  } as const);
