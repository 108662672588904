import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from 'services/userService/user.service';

export const getCombinedResultsThunk = createAsyncThunk(
  'user/getCombinedResultsThunk',
  async (userId: string, thunkAPI) => {
    try {
      return await UserAPI.getCombinedResults(userId);
    } catch (e: any) {
      throw e.response.data.message
        ? e.response.data.message
        : 'Something went wrong while getting your questionnaire results...';
    }
  },
);
