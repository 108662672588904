import { useEffect, useState } from 'react';

export const useResizeWindow = () => {
  const [sizes, setSizes] = useState({ width: window.innerWidth, height: window.innerHeight });

  const handleResize = () => {
    setSizes({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return sizes;
};
