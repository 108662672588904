import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getWelcomeThunk } from './thunk/getWelcomeThunk'
import { type Welcome } from 'models/welcome/Welcome'

export interface WelcomeState {
  welcome: Welcome | null;
  loading: boolean;
}

const initialState: WelcomeState = {
  welcome: null,
  loading: false
}

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {},
  extraReducers: {
    [getWelcomeThunk.fulfilled.type]: (state, action: PayloadAction<Welcome>) => {
      state.welcome = action.payload
      state.loading = false
    },
    [getWelcomeThunk.rejected.type]: (state, _action) => {
      state.loading = false
    },
    [getWelcomeThunk.pending.type]: (state, _action) => {
      state.loading = true
    }
  }
})

export default welcomeSlice.reducer
